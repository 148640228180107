import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";

import Card from "components/Card";
import PlaylistEditDialog from "./EditDialog";
import PlaylistDetailsPage from "./PlaylistDetails";

import { apiCall } from "functions/api";
import { Playlist } from "types/playlists";
import { Tag } from "types/tags";
import DataTable from "components/DataTable";
import { Stack, Typography } from "@mui/material";
import Image from "components/Image";

const PlaylistsListPage = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<Playlist[]>();
  const [tags, setTags] = React.useState<Tag[]>();
  const navigate = useNavigate();

  const columns = React.useMemo<GridColDef<Playlist>[]>(
    () => [
      {
        field: "playlist",
        headerName: "Playlist",
        valueGetter: (_, row) => row.metadata.name,
        renderCell: (params) => {
          let playlist = params.row;
          let text =
            playlist.metadata.date || playlist.metadata.year.toString();
          if (playlist.metadata.location) {
            text += " • " + playlist.metadata.location;
          }
          return (
            <Stack
              direction="row"
              alignItems="center"
              sx={{ height: "100%" }}
              gap={2}
            >
              <Image image={playlist.image} count={playlist.sequence.length} />
              <Stack overflow="hidden">
                <Typography
                  overflow="hidden"
                  textOverflow="ellipsis"
                  variant="body1"
                >
                  {playlist.metadata.name}
                </Typography>
                <Typography
                  textOverflow="ellipsis"
                  variant="body2"
                  sx={{ color: "#555555" }}
                >
                  {text}
                </Typography>
              </Stack>
            </Stack>
          );
        },
        minWidth: 550,
      },
      {
        field: "type",
        headerName: "Type",
        valueGetter: (_, row) => (row.type === "topic" ? "Topic" : "Event"),
        width: 150,
      },
    ],
    []
  );

  const loadData = () => {
    setData(undefined);
    apiCall("/playlists").then(
      (response) => {
        setData(response.data);
      },
      (e) => console.error(e)
    );
    apiCall("/tags", "GET").then(
      (response) => {
        setTags(response.data);
      },
      (e) => {
        console.error(e);
      }
    );
  };

  const handleDialogClose = (reload: boolean) => {
    setOpen(false);
    if (reload) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card
        title="Playlists"
        button="Add"
        buttonOnClick={() => setOpen(true)}
        empty={data?.length === 0}
        padding={false}
      >
        <DataTable
          rowHeight={80}
          loading={!data || !tags}
          rows={data || []}
          columns={columns}
          rowSelection={false}
          disableRowSelectionOnClick
          onRowClick={(params) => navigate(`/playlists/${params.row.id}`)}
        />
      </Card>
      <PlaylistEditDialog
        open={open}
        onClose={handleDialogClose}
        allTags={tags || []}
      />
    </div>
  );
};

const PlaylistsPage = () => {
  return (
    <Routes>
      <Route path="/" element={<PlaylistsListPage />} />
      <Route path="/:id" element={<PlaylistDetailsPage />} />
      <Route path="/*" element={<Navigate to="/playlists" />} />
    </Routes>
  );
};

export default PlaylistsPage;
