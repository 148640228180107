import { Location } from "react-router-dom";

export const youtubeIdRegex =
  /^https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})$/;
export const youtubeIdOptionalRegex =
  /^(https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})|)$/;

export const durationOptionalRegex = /^\d*:[0-5]?[0-9]?:[0-5]?[0-9]?$/;


export function activeRoute(routeName: string, location: Location) {
  const p = location.pathname;
  if (routeName === "/") return p === "/";
  return p.includes(routeName.replace("/*", ""));
}

export function arrayEquals(a: any[], b: any[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function getDurationString(duration?: number) {
  if (duration === undefined) {
    return "";
  }
  // Include hours as well. Always results 00:00:00 format using pading
  const hours = String(Math.floor(duration / 3600));
  const minutes = String(Math.floor((duration % 3600) / 60));
  const seconds = String(Math.floor(duration % 60));
  return `${hours}:${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
}

export function getDurationInSeconds(durationString: string) {
  const [hours, minutes, seconds] = durationString.split(":");
  return (
    parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds) * 1
  );
}
