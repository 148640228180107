import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import Card from "components/Card";
import Details from "components/Details";
import ConfirmDialog from "components/ConfirmDialog";
import PlaylistEditDialog from "./EditDialog";
import MediaDialog from "./MediaDialog";
import ReorderDialog from "./ReorderDialog";
import FileDialog from "components/FileDialog";

import { apiCall } from "functions/api";
import { mediaKeyNames } from "functions/media";
import { useURLBase } from "functions/context";

import type {
  Playlist,
  UpdateRequest as UpdatePlaylistRequest,
} from "types/playlists";
import type {
  Media,
  MediaType,
  UpdateRequest as UpdateMediaRequest,
} from "types/media";
import type { Tag, TagContent } from "types/tags";
import type { StorageProviderType } from "types/utils";
import { Stack, Typography } from "@mui/material";
import Image from "components/Image";
import DataTable from "components/DataTable";
import YoutubeDialog from "./YoutubeDialog";

const PlaylistDetailsPage = () => {
  const params = useParams<"id">();
  const navigate = useNavigate();
  const storageURLBase = useURLBase();

  const [playlist, setPlaylist] = React.useState<Playlist>();
  const [tags, setTags] = React.useState<Tag[]>();
  const [playlistTags, setPlaylistTags] = React.useState<number[]>();
  const [editMedia, setEditMedia] = React.useState<Media>();
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [mediaYoutubeDialogOpen, setMediaYoutubeDialogOpen] =
    React.useState(false);
  const [mediaAudioDialogOpen, setMediaAudioDialogOpen] = React.useState(false);
  const [mediaVideoDialogOpen, setMediaVideoDialogOpen] = React.useState(false);
  const [mediaImageDialogOpen, setMediaImageDialogOpen] = React.useState(false);
  const [startLiveDialogOpen, setStartLiveDialogOpen] = React.useState(false);
  const [closeLiveDialogOpen, setCloseLiveDialogOpen] = React.useState(false);
  const [liveDetailsDialogOpen, setLiveDetailsDialogOpen] =
    React.useState(false);
  const [playlistImageDialogOpen, setPlaylistImageDialogOpen] =
    React.useState(false);
  const [mediaEditDialogOpen, setMediaEditDialogOpen] = React.useState(false);
  const [mediaDeleteDialogOpen, setMediaDeleteDialogOpen] =
    React.useState(false);
  const [reorderDialogKey, setReorderDialogKey] = React.useState<MediaType>();

  const loadData = () => {
    setPlaylist(undefined);
    apiCall(`/playlists/${params.id}`, "GET", { expand: true }).then(
      (response) => {
        setPlaylist(response);
      },
      (e) => {
        console.error(e);
        navigate("/playlists", { replace: true });
      }
    );
    apiCall("/tags", "GET").then(
      (response) => {
        setTags(response.data);
      },
      (e) => {
        console.error(e);
      }
    );
    apiCall(`/playlists/${params.id}/tags`, "GET").then(
      (response) => {
        setPlaylistTags(response.data.map((tag: TagContent) => tag.tag_id));
      },
      (e) => {
        console.error(e);
      }
    );
  };

  const handleDialogClose = (reload: boolean) => {
    setEditDialogOpen(false);
    setDeleteDialogOpen(false);
    setMediaEditDialogOpen(false);
    setMediaDeleteDialogOpen(false);
    setMediaImageDialogOpen(false);
    setMediaYoutubeDialogOpen(false);
    setMediaAudioDialogOpen(false);
    setMediaVideoDialogOpen(false);
    setPlaylistImageDialogOpen(false);
    setReorderDialogKey(undefined);
    setEditMedia(undefined);
    setStartLiveDialogOpen(false);
    setCloseLiveDialogOpen(false);
    setLiveDetailsDialogOpen(false);
    if (reload) {
      loadData();
    }
  };

  const editPlaylistImage = async (
    file?: string,
    storage?: StorageProviderType
  ) => {
    if (!playlist) {
      return;
    }
    const request: UpdatePlaylistRequest = {
      image: {
        main: file
          ? {
              id: file,
              source: storage!,
            }
          : null,
      },
    };
    await apiCall(`/playlists/${playlist.id}`, "POST", request);
    if (file) {
      try {
        await apiCall(
          `/playlists/${playlist.id}/generate_small_images`,
          "POST"
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const editMediaImage = async (
    file?: string,
    storage?: StorageProviderType
  ) => {
    if (!editMedia) {
      return;
    }
    const request: UpdateMediaRequest = {
      image: {
        main: file
          ? {
              id: file,
              source: storage!,
            }
          : null,
      },
    };
    await apiCall(`/media/${editMedia.id}`, "POST", request);
    if (file) {
      try {
        await apiCall(`/media/${editMedia.id}/generate_small_images`, "POST");
      } catch (e) {
        console.error(e);
      }
    }
  };

  const editMediaAudio = async (
    file?: string,
    storage?: StorageProviderType
  ) => {
    if (!editMedia) {
      return;
    }
    const request: UpdateMediaRequest = {
      audio: {
        main: file
          ? {
              id: file,
              source: storage!,
            }
          : null,
      },
    };
    await apiCall(`/media/${editMedia.id}`, "POST", request);
  };

  const editMediaVideo = async (
    file?: string,
    storage?: StorageProviderType
  ) => {
    if (!editMedia) {
      return;
    }
    const request: UpdateMediaRequest = {
      video: {
        main: file
          ? {
              id: file,
              source: storage!,
            }
          : null,
      },
    };
    await apiCall(`/media/${editMedia.id}`, "POST", request);
  };

  const handleMediaEdit = (media: Media) => {
    setEditMedia(media);
    setMediaEditDialogOpen(true);
  };

  const handleMediaAudioEdit = (media: Media) => {
    setEditMedia(media);
    setMediaAudioDialogOpen(true);
  };

  const handleMediaVideoEdit = (media: Media) => {
    setEditMedia(media);
    setMediaVideoDialogOpen(true);
  };

  const handleMediaYoutubeEdit = (media: Media) => {
    setEditMedia(media);
    setMediaYoutubeDialogOpen(true);
  };

  const handleMediaImageEdit = (media: Media) => {
    setEditMedia(media);
    setMediaImageDialogOpen(true);
  };

  const handleMediaDelete = (media: Media) => {
    setEditMedia(media);
    setMediaDeleteDialogOpen(true);
  };

  const handleStartLive = (media: Media) => {
    setEditMedia(media);
    setStartLiveDialogOpen(true);
  };

  const handleCloseLive = (media: Media) => {
    setEditMedia(media);
    setCloseLiveDialogOpen(true);
  };

  const handleLiveDetails = (media: Media) => {
    setEditMedia(media);
    setLiveDetailsDialogOpen(true);
  };

  const handleReorder = (key: MediaType) => {
    setReorderDialogKey(key);
  };

  const handleGenerateImage = (media: Media) => {
    apiCall(`/media/${media.id}/generate_image`, "POST")
      .then((response) => {
        console.log(response);
        return apiCall(`/media/${media.id}/generate_small_images`, "POST");
      })
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleGenerateAudio = (media: Media) => {
    apiCall(`/media/${media.id}/generate_audio`, "POST").then(
      (response) => {
        console.log(response);
      },
      (e) => {
        console.error(e);
      }
    );
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const columns = React.useMemo(
    () =>
      [
        {
          field: "media",
          headerName: "Media",
          renderCell: (params) => {
            let media = params.row;
            let text;
            if (media.live_status && media.live) {
              text = "Live • ";
              switch (media.live_status) {
                case "scheduled":
                  text +=
                    "Scheduled at " +
                    new Date(media.live.schedule_time * 1000).toLocaleString();
                  break;
                case "ongoing":
                  text += "Currently live";
                  break;
                case "complete":
                  text += "Ended at " + new Date(media.live.end_time! * 1000).toLocaleString();
                  break;
                case "post_processing":
                  text += "Post processing";
                  break;
                case "preparing":
                  text += "Preparing";
                  break;
                default:
                  text += media.live_status;
                  break;
              }
            } else {
              text = media.metadata.date || media.metadata.year.toString();
              if (media.metadata.location) {
                text += " • " + media.metadata.location;
              }
            }
            return (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: "100%" }}
                gap={2}
              >
                <Image image={media.image} />
                <Stack overflow="hidden">
                  <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    variant="body1"
                  >
                    {media.metadata.name}
                  </Typography>
                  <Typography
                    textOverflow="ellipsis"
                    variant="body2"
                    sx={{ color: media.live ? "red" : "#555555" }}
                  >
                    {text}
                  </Typography>
                </Stack>
              </Stack>
            );
          },
          minWidth: 550,
          width: 150,
          sortable: false,
        },
        {
          field: "links",
          headerName: "Links",
          renderCell: (params) => {
            const media = params.row;
            if (!media.video.main && !media.audio.main) {
              return <em>None</em>;
            }
            const view: React.ReactElement[] = [];
            if (media.video.main) {
              view.push(
                <a
                  href={
                    storageURLBase[media.video.main.source] +
                    media.video.main.id
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: "10px" }}
                >
                  {media.video.main.source === "youtube" ? "YouTube" : "Video"}
                </a>
              );
            }
            if (media.audio.main) {
              view.push(
                <a
                  href={
                    storageURLBase[media.audio.main.source] +
                    media.audio.main.id
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: "10px" }}
                >
                  Audio
                </a>
              );
            }
            if (media.image.main && media.image.main.source !== "youtube") {
              view.push(
                <a
                  href={
                    storageURLBase[media.image.main.source] +
                    media.image.main.id
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: "10px" }}
                >
                  Image
                </a>
              );
            }
            return view;
          },
          width: 150,
          sortable: false,
        },
        {
          field: "actions",
          type: "actions",
          align: "right",
          flex: 1,
          getActions: (params) => {
            let d = [
              <GridActionsCellItem
                label="Edit"
                showInMenu
                onClick={() => handleMediaEdit(params.row)}
              />,
              <GridActionsCellItem
                label="Edit Audio"
                showInMenu
                onClick={() => handleMediaAudioEdit(params.row)}
              />,
              <GridActionsCellItem
                label="Youtube Link"
                showInMenu
                onClick={() => handleMediaYoutubeEdit(params.row)}
              />,
              <GridActionsCellItem
                label="Edit Video"
                showInMenu
                onClick={() => handleMediaVideoEdit(params.row)}
              />,
              <GridActionsCellItem
                label="Edit Image"
                showInMenu
                onClick={() => handleMediaImageEdit(params.row)}
              />,
              <GridActionsCellItem
                label="Generate Image"
                disabled={!params.row.video.main}
                showInMenu
                onClick={() => handleGenerateImage(params.row)}
              />,
              <GridActionsCellItem
                label="Generate Audio"
                disabled={!!params.row.audio.main}
                showInMenu
                onClick={() => handleGenerateAudio(params.row)}
              />,
              <GridActionsCellItem
                label="Delete"
                showInMenu
                onClick={() => handleMediaDelete(params.row)}
              />,
            ];
            if (params.row.live) {
              return [
                <GridActionsCellItem
                  label="Live Details"
                  showInMenu
                  onClick={() => handleLiveDetails(params.row)}
                />,
                <GridActionsCellItem
                  label="Start Live"
                  disabled={params.row.live_status !== "scheduled"}
                  showInMenu
                  onClick={() => handleStartLive(params.row)}
                />,
                <GridActionsCellItem
                  label="End Live"
                  disabled={params.row.live_status !== "ongoing"}
                  showInMenu
                  onClick={() => handleCloseLive(params.row)}
                />,
                ...d,
              ];
            }
            return d;
          },
        },
      ] as GridColDef<Media>[],
    [storageURLBase]
  );

  const mediaList = React.useMemo(
    () =>
      (playlist?.sequence_expanded?.filter((media) => media) || []) as Media[],
    [playlist]
  );

  const groupedList = React.useMemo(
    () =>
      mediaKeyNames.map((item) => {
        const filteredList = mediaList!.filter(
          (media) => media.type === item.key
        );
        return {
          ...item,
          list: (filteredList ? filteredList : []) as Media[],
        };
      }),
    [mediaList]
  );

  if (!playlist || !tags || !playlistTags) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Card
        title={playlist.metadata.name}
        button="Add Media"
        buttonOnClick={() => setMediaEditDialogOpen(true)}
        menu={[
          {
            label: "Edit",
            onClick: () => setEditDialogOpen(true),
          },
          {
            label: "Edit Image",
            onClick: () => setPlaylistImageDialogOpen(true),
          },
          {
            label: "Delete",
            onClick: () => setDeleteDialogOpen(true),
          },
        ]}
      >
        <Details label="Description">{playlist.metadata.description}</Details>
        <Details label="Location">{playlist.metadata.location}</Details>
        <Details label="Date">
          {playlist.metadata.date || playlist.metadata.year}
        </Details>
        <Details label="Type">
          {playlist.type === "topic" ? "Topic" : "Event"}
        </Details>
        <Details label="Image">
          {playlist.image.main ? (
            <a
              href={
                storageURLBase[playlist.image.main.source] +
                playlist.image.main.id
              }
              target="_blank"
              rel="noreferrer"
            >
              View Image
            </a>
          ) : (
            <em>No image uploaded</em>
          )}
        </Details>
        <Details label="Tags">
          {playlistTags.length === 0 && <i>None</i>}
          {playlistTags.map((tagId, key) => {
            const tag = tags.find((tag) => tag.id === tagId);
            if (!tag) {
              return null;
            }
            return <Chip key={key} label={tag.metadata.name} />;
          })}
        </Details>
      </Card>
      {groupedList.map((item, key) => (
        <React.Fragment key={key}>
          <br />
          <Card
            title={item.name}
            button="Reorder"
            buttonOnClick={() => handleReorder(item.key)}
            buttonDisabled={item.list.length === 0}
            empty={item.list.length === 0}
            padding={false}
          >
            <DataTable<Media>
              rowHeight={80}
              rows={item.list}
              columns={columns}
              pageSizeOptions={[50]}
              rowSelection={false}
              disableRowSelectionOnClick
              disableColumnMenu
            />
          </Card>
        </React.Fragment>
      ))}
      <PlaylistEditDialog
        open={editDialogOpen}
        onClose={handleDialogClose}
        playlist={playlist}
        allTags={tags}
        playlistTags={playlistTags}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        apiLink={`/playlists/${playlist?.id}`}
        title="Delete Playlist"
        text="Are you sure you want to delete this playlist?"
      />
      <ConfirmDialog
        open={mediaDeleteDialogOpen}
        onClose={handleDialogClose}
        apiLink={`/media/${editMedia?.id}`}
        title="Delete Media"
        text="Are you sure you want to delete this media?"
      />
      <ConfirmDialog
        open={mediaDeleteDialogOpen}
        onClose={handleDialogClose}
        apiLink={`/media/${editMedia?.id}`}
        title="Delete Media"
        text="Are you sure you want to delete this media?"
      />
      <ConfirmDialog
        open={startLiveDialogOpen}
        onClose={handleDialogClose}
        apiLink={`/media/${editMedia?.id}/start_live`}
        method="POST"
        title="Start Live"
        text="Are you sure you want to start this live?"
      />
      <ConfirmDialog
        open={closeLiveDialogOpen}
        onClose={handleDialogClose}
        apiLink={`/media/${editMedia?.id}/complete_live`}
        method="POST"
        title="End Live"
        text="Are you sure you want to end this live?"
      />
      <ConfirmDialog
        open={liveDetailsDialogOpen}
        onClose={handleDialogClose}
        title="Live Details"
        confirmButton="Ok"
        text={
          editMedia?.live ? (
            <Typography sx={{ "& b": { fontWeight: "500" } }}>
              <b>Start Time:</b>{" "}
              {editMedia.live.start_time
                ? new Date(editMedia.live.start_time).toLocaleString()
                : "N/A"}
              <br />
              <b>End Time:</b>{" "}
              {editMedia.live.end_time
                ? new Date(editMedia.live.end_time).toLocaleString()
                : "N/A"}
              <br />
              <b>Stream Key:</b>{" "}
              {editMedia.live.stream_url?.split("/").pop() || "N/A"}
              <br />
              <b>Stream URL:</b>{" "}
              {editMedia.live.stream_url?.split("/").slice(0, -1).join("/") ||
                "N/A"}
              <br />
            </Typography>
          ) : (
            "No live details available"
          )
        }
      />
      <MediaDialog
        open={mediaEditDialogOpen}
        onClose={handleDialogClose}
        playlist={playlist}
        all={mediaList}
        media={editMedia}
      />
      <YoutubeDialog
        open={mediaYoutubeDialogOpen}
        onClose={handleDialogClose}
        media={editMedia}
      />
      <ReorderDialog
        open={!!reorderDialogKey}
        onClose={handleDialogClose}
        playlist={playlist}
        type={reorderDialogKey || "speech"}
      />
      <FileDialog
        open={playlistImageDialogOpen}
        title="Edit Image"
        type="image"
        onClose={handleDialogClose}
        editFile={editPlaylistImage}
        deleteFile={(playlist.image.main || undefined) && editPlaylistImage}
      />
      <FileDialog
        open={mediaImageDialogOpen}
        title="Edit Image"
        type="image"
        onClose={handleDialogClose}
        editFile={editMediaImage}
        deleteFile={(editMedia?.image.main || undefined) && editMediaImage}
      />
      <FileDialog
        open={mediaAudioDialogOpen}
        title="Edit Audio"
        type="audio"
        onClose={handleDialogClose}
        editFile={editMediaAudio}
        deleteFile={(editMedia?.audio.main || undefined) && editMediaAudio}
      />
      <FileDialog
        open={mediaVideoDialogOpen}
        title="Edit Video"
        type="video"
        onClose={handleDialogClose}
        editFile={editMediaVideo}
        deleteFile={(editMedia?.video.main || undefined) && editMediaVideo}
      />
    </div>
  );
};

export default PlaylistDetailsPage;
