import React from "react";

import Dialog from "./Dialog";
import { apiCall } from "functions/api";

interface Props {
  open: boolean;
  onClose: (successful: boolean) => void;
  confirm?: () => Promise<any>;
  apiLink?: string;
  title: string;
  text: React.ReactNode;
  cancelButton?: string;
  confirmButton?: string;
  method?: "DELETE" | "POST";
}

const ConfirmDialog = (props: Props) => {
  const [submitting, setSubmitting] = React.useState(false);

  const handleConfirm = () => {
    setSubmitting(true);
    let promise = Promise.resolve();
    if (props.confirm) {
      promise = props.confirm();
    } else if (props.apiLink) {
      promise = apiCall(props.apiLink, props.method || "DELETE");
    }
    promise
      .then(() => {
        setSubmitting(false);
        props.onClose(true);
      })
      .catch((e) => {
        console.error(e);
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      title={props.title}
      width="sm"
      onSubmit={handleConfirm}
      actions={[
        {
          text: props.cancelButton || "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: props.confirmButton || (submitting ? "Confirming" : "Confirm"),
          disabled: submitting,
          color: "primary",
          submit: true,
        },
      ]}
    >
      {props.text}
    </Dialog>
  );
};

export default ConfirmDialog;
