import { ImageInfo } from "types/utils";

const imageSizes: Array<keyof ImageInfo> = ["xs", "sm", "md", "main"];

export const getImage = (
  image: ImageInfo,
  target: keyof ImageInfo
): ImageInfo['xs'] => {
  const index = imageSizes.indexOf(target);
  for (let i = index; i < imageSizes.length; i++) {
    const size = image[imageSizes[i]];
    if (size && size) {
      return size;
    }
  }
  return null;
};
