import React from "react";

import Dialog from "components/Dialog";
import ValidatedInput, { initialValue } from "components/ValidatedInput";

import { apiCall } from "functions/api";
import { youtubeIdOptionalRegex } from "functions/utils";

import type { Media, UpdateRequest } from "types/media";

interface Props {
  open: boolean;
  media?: Media;
  onClose: (reload: boolean) => void;
}

const YoutubeDialog = (props: Props) => {
  const [youtubeLink, setYoutubeLink] = React.useState(
    initialValue(youtubeIdOptionalRegex)
  );
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState("");

  const error = React.useMemo(() => {
    return !youtubeLink?.success;
  }, [youtubeLink]);

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = async () => {
    if (error) {
      return;
    }
    setSubmitting(true);
    setServerError("");
    try {
      const request: UpdateRequest = {};
      const youtubeId = youtubeLink.value.match(youtubeIdOptionalRegex)?.[2];
      if (!props.media) {
        return;
      }
      if (
        !props.media.video.main ||
        props.media.video.main?.source === "youtube"
      ) {
        if (youtubeId) {
          request.video = {
            main: {
              id: youtubeId,
              source: "youtube",
            },
          };
        } else {
          request.video = { main: null };
        }
      }
      await apiCall(`/media/${props.media.id}`, "POST", request);
      if (youtubeId) {
        try {
          await apiCall(`/media/${props.media.id}/generate_image`, "POST");
          await apiCall(
            `/media/${props.media.id}/generate_small_images`,
            "POST"
          );
        } catch (e) {
          console.error(e);
        }
      }
      setSubmitting(false);
      props.onClose(true);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      setServerError("An error occurred");
    }
  };

  React.useEffect(() => {
    if (props.open) {
      const media = props.media;
      if (media) {
        if (media.video?.main?.source === "youtube") {
          setYoutubeLink(
            initialValue(
              youtubeIdOptionalRegex,
              `https://www.youtube.com/watch?v=${media.video.main.id}`
            )
          );
        } else {
          setYoutubeLink(initialValue(youtubeIdOptionalRegex));
        }
      }
    }
  }, [props.open, props.media]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={"Youtube Link"}
      errorMessage={serverError}
      actions={[
        {
          text: "Cancel",
          color: "secondary",
          onClick: handleClose,
        },
        {
          text: submitting ? "Submitting" : "Submit",
          color: "primary",
          submit: true,
          disabled: error || submitting,
        },
      ]}
    >
      <ValidatedInput
        label="Youtube Link"
        value={youtubeLink}
        onChange={setYoutubeLink}
        type={youtubeIdOptionalRegex}
        disabled={
          !!props.media?.video.main &&
          props.media?.video.main?.source !== "youtube"
        }
      />
    </Dialog>
  );
};

export default YoutubeDialog;
