import React from "react";

import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  DataGrid,
  GridActionsCellItem,
  type DataGridProps,
  type GridColDef,
  type GridRowModel,
} from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";

interface DataTableProps<T extends GridRowModel>
  extends Omit<DataGridProps<T>, "rows" | "columns"> {
  rows?: any[];
  columns: (GridColDef<T> & { visible?: boolean })[];
}

export default function DataTable<T extends GridRowModel = any>(
  props: DataTableProps<T>
) {
  const { rows, columns, onRowClick, ...rest } = props;

  const finalColumns = React.useMemo(() => {
    const arrowAction: GridColDef<T> = {
      field: "actions",
      headerName: "",
      minWidth: 50,
      flex: 1,
      align: "right",
      renderCell: () => (
        <GridActionsCellItem
          key={0}
          label="View"
          size="large"
          icon={<KeyboardArrowRight />}
          disableRipple
        />
      ),
    };
    const defaultProps: Partial<GridColDef<T>> = {
      // sortable: false,
      // filterable: false,
      hideable: false,
      disableColumnMenu: true,
      // minWidth: 50,
      // flex: 1,
    };
    let list = [
      ...columns.filter((v) => v?.visible !== false),
      !!onRowClick && arrowAction,
    ];
    list = list.filter(Boolean);
    list = list.map(
      (props) =>
        props && {
          ...defaultProps,
          ...props,
        }
    );
    return list as GridColDef<T>[];
  }, [columns]);

  return (
    <DataGrid<T>
      paginationMode="client"
      {...rest}
      rowSelection={false}
      disableRowSelectionOnClick
      onRowClick={onRowClick}
      columns={finalColumns}
      slotProps={{
        loadingOverlay: {
          variant: "skeleton",
          noRowsVariant: "skeleton",
        },
      }}
      slots={{
        noRowsOverlay: () => (
          <Stack alignItems="center" justifyContent="center">
            No Data
          </Stack>
        ),
      }}
      rows={rows}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      sx={{
        minHeight: 400,
        "& .MuiDataGrid-scrollbar::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "& .MuiDataGrid-scrollbar::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb": {
          backgroundColor: "#999999",
          borderRadius: 10,
        },
        "& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#888888",
        },
        border: "none",
        "& .MuiDataGrid-container--top [role=row]": {
          backgroundColor: "background.paper",
        },
        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
          outline: "none !important",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: onRowClick ? "pointer" : "default",
          backgroundColor: onRowClick ? "rgba(0, 0, 0, 0.04)" : "transparent",
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      }}
    />
  );
}
