import Box from "@mui/material/Box";
import { useURLBase } from "functions/context";
import PlaylistIcon from "@mui/icons-material/PlaylistPlay";

import { ImageInfo } from "types/utils";
import { getImage } from "utils";

export default function Image({
  image: _image,
  count,
}: {
  image: ImageInfo;
  count?: number;
}) {
  const urlBase = useURLBase();
  const image = getImage(_image, "main");
  return (
    <Box
      sx={{
        minWidth: "120px",
        position: "relative",
        maxWidth: "120px",
        height: "68px",
        overflow: "hidden",
        "& .media-image": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "5px",
          backgroundColor: "#cccccc",
        },
      }}
    >
      {count ? (
        <Box
          sx={{
            zIndex: 10,
            position: "absolute",
            bottom: 0,
            lineHeight: 1,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "3px",
            pr: 1,
            borderRadius: "5px 0 5px 0",
            fontSize: "0.7rem",
            verticalAlign: "middle",
          }}
        >
          <PlaylistIcon fontSize="small" sx={{ verticalAlign: "middle" }} />{" "}
          <span style={{ verticalAlign: "middle" }}>{count}</span>
        </Box>
      ) : null}
      {!!image && (
        <img
          className="media-image"
          src={urlBase[image.source] + image.id}
          alt="media"
        />
      )}
      {!image && <Box className="media-image"></Box>}
    </Box>
  );
}
