import React from "react";
import TextField from "@mui/material/TextField";

export type ValidatedValue = {
  value: string;
  state: "error" | "success" | "";
  success: boolean;
};

type DefaultType =
  | "any"
  | "email"
  | "password"
  | "text"
  | "number"
  | "date"
  | "dateTime"
  | "duration";

type Props = Omit<
  React.ComponentProps<typeof TextField>,
  "value" | "onChange" | "type"
> & {
  value: ValidatedValue;
  onChange: (value: ValidatedValue) => void;
  type: DefaultType | RegExp;
};

const anyRegex = /.*/;
const textRegex = /^.+$/;
const numberRegex = /^\d+$/;
const emailRegex = /^\S+@\S+\.\S+$/;
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2} (am|pm)$/;
const durationRegex = /^\d+:[0-5][0-9]:[0-5][0-9]$/;

const regexMap: Record<DefaultType, RegExp> = {
  any: anyRegex,
  email: emailRegex,
  password: textRegex,
  text: textRegex,
  number: numberRegex,
  date: dateRegex,
  dateTime: dateTimeRegex,
  duration: durationRegex,
};

export const getRegex = (type: Props["type"]): RegExp => {
  if (type instanceof RegExp) {
    return type;
  }
  return regexMap[type];
};

export const initialValue = (
  type: Props["type"],
  value: string = ""
): ValidatedValue => {
  const regex = getRegex(type);
  return {
    value: value,
    state: value === "" ? "" : regex.test(value) ? "success" : "error",
    success: regex.test(value),
  };
};

const ValidatedInput = (props: Props) => {
  const { value, onChange, type, ...rest } = props;

  const regex: RegExp = React.useMemo(() => getRegex(type), [type]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const isValid = regex.test(newValue);
    onChange({
      value: newValue,
      state: isValid ? "success" : "error",
      success: isValid,
    });
  };

  return (
    <TextField
      required={!(type instanceof RegExp) && type !== "any"}
      {...rest}
      type={type === "password" ? "password" : "text"}
      value={value?.value || ""}
      onChange={handleChange}
      error={value?.state === "error"}
    />
  );
};

export default ValidatedInput;
